import React, { memo } from "react";

const ChatIcon = memo(() => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.997 6h-13.86a1.032 1.032 0 00-.71.283.965.965 0 00-.293.685v4.352h7.104a2.41 2.41 0 011.669.669c.443.428.692 1.007.693 1.612v2.966l3.866 2.402c.352.22.817-.022.822-.427l.024-2.227h.685c.266 0 .52-.103.709-.285a.954.954 0 00.294-.684V6.969a.952.952 0 00-.294-.684A1.022 1.022 0 0026.997 6z"
      fill="currentColor"
    />
    <path
      d="M5.028 23.182h.7l.027 2.308c.005.405.47.646.822.427l4.401-2.735h8.26a1.06 1.06 0 00.727-.29.992.992 0 00.3-.703v-8.588a.976.976 0 00-.3-.702 1.047 1.047 0 00-.727-.291H5.028c-.273 0-.534.104-.727.29a.975.975 0 00-.301.703v8.588a.963.963 0 00.301.702 1.031 1.031 0 00.727.29z"
      fill="currentColor"
    />
  </svg>
));

export default ChatIcon;
