export const BUTTON_SIZE = {
  sm: "sm",
  md: "md",
  lg: "lg",
};

export const BUTTON_KIND = {
  primary: "primary",
  secondary: "secondary",
  clear: "clear",
  text: "text",
};
