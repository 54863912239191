import React from "react";
import { useMedia } from "use-media";

import Logo from "../Logo/Logo";
import Icon from "../Icon/Icon";
import Button from "../Button/Button";

import * as ICONS from "../../constants/icons";
import { BUTTON_KIND } from "../../constants/button";
import { EXTERNAL_LINKS } from "../../constants/links";
import { MOBILE_SCREEN } from "../../constants/breakpoints";
import { APP_VERSION } from "../../constants/global";

import googlePlayImage from "../../assets/images/google-play.png";
import appleStoreImage from "../../assets/images/apple-store.png";

import styles from "./Footer.module.scss";

const Footer = () => {
  const isWide = useMedia({ minWidth: MOBILE_SCREEN });
  const logoSize = isWide ? { width: 112, height: 36 } : { width: 88, height: 26 };

  return (
    <footer className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.socialSection}>
          <div className={styles.brandContainer}>
            <Logo logoClassName={styles.logo} width={logoSize.width} height={logoSize.height} />

            <p className={styles.privacy}>&copy; 2020 Telmie Inc. All rights reserved.</p>

            <p className={styles.privacy}>Version {APP_VERSION}</p>
          </div>

          <div className={styles.socialContainer}>
            <Button link href={EXTERNAL_LINKS.youtube} kind={BUTTON_KIND.clear} className={styles.socialLink}>
              <Icon name={ICONS.YOUTUBE_ICON} />
            </Button>

            <Button link href={EXTERNAL_LINKS.instagram} kind={BUTTON_KIND.clear} className={styles.socialLink}>
              <Icon name={ICONS.INSTAGRAM_ICON} />
            </Button>

            <Button link href={EXTERNAL_LINKS.linkedin} kind={BUTTON_KIND.clear} className={styles.socialLink}>
              <Icon name={ICONS.LINKEDIN_ICON} />
            </Button>

            <Button link href={EXTERNAL_LINKS.twitter} kind={BUTTON_KIND.clear} className={styles.socialLink}>
              <Icon name={ICONS.TWITTER_ICON} />
            </Button>

            <Button link href={EXTERNAL_LINKS.facebook} kind={BUTTON_KIND.clear} className={styles.socialLink}>
              <Icon name={ICONS.FACEBOOK_ICON} />
            </Button>
          </div>
        </div>

        <div className={styles.navContainer}>
          <nav className={styles.nav}>
            <ul>
              <li className={styles.label}>Company</li>
              <li className={styles.linkContainer}>
                <a href={EXTERNAL_LINKS.signUp} className={styles.link}>
                  About us
                </a>
              </li>
              <li className={styles.linkContainer}>
                <a href={EXTERNAL_LINKS.signUp} className={styles.link}>
                  Newsroom
                </a>
              </li>
              <li className={styles.linkContainer}>
                <a href={EXTERNAL_LINKS.signUp} className={styles.link}>
                  Investors
                </a>
              </li>
              <li className={styles.linkContainer}>
                <a href={EXTERNAL_LINKS.blog} className={styles.link}>
                  Blog
                </a>
              </li>
              <li className={styles.linkContainer}>
                <a href={EXTERNAL_LINKS.signUp} className={styles.link}>
                  Careers
                </a>
              </li>
            </ul>
          </nav>
          <nav className={styles.nav}>
            <ul>
              <li className={styles.label}>About</li>
              <li className={styles.linkContainer}>
                <a href={EXTERNAL_LINKS.privacy} className={styles.link}>
                  Privacy
                </a>
              </li>
              <li className={styles.linkContainer}>
                <a href={EXTERNAL_LINKS.signUp} className={styles.link}>
                  Accessibility
                </a>
              </li>
              <li className={styles.linkContainer}>
                <a href={EXTERNAL_LINKS.terms} className={styles.link}>
                  Terms
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <div className={styles.languageSection}>
          <div className={styles.storeButtonContainer}>
            <Button link href={EXTERNAL_LINKS.googlePlay} kind={BUTTON_KIND.clear} className={styles.storeButton}>
              <img alt="google play" src={googlePlayImage} width={136} height={40} />
            </Button>
            <Button link href={EXTERNAL_LINKS.appStore} kind={BUTTON_KIND.clear} className={styles.storeButton}>
              <img alt="apple store" src={appleStoreImage} width={136} height={40} />
            </Button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
