import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useMedia } from "use-media";
import classNames from "classnames";
import PropTypes from "prop-types";

import Logo from "../Logo/Logo";
import Button from "../Button/Button";
import MenuButton from "../MenuButton/MenuButton";

import { EXTERNAL_LINKS } from "../../constants/links";
import { WHAT_IS_TELMIE } from "../../constants/routes";
import { MOBILE_SCREEN } from "../../constants/breakpoints";

import styles from "./Header.module.scss";

const Header = ({ open, onToggleMenu }) => {
  const isNarrow = useMedia({ maxWidth: MOBILE_SCREEN });
  const logoSize = isNarrow ? { width: 106, height: 32 } : { width: 112, height: 36 };

  return (
    <>
      <header className={styles.container}>
        <Logo
          logoClassName={classNames(styles.logo, {
            [styles.active]: open,
          })}
          containerClassName={classNames(styles.logoContainer, {
            [styles.active]: open,
          })}
          width={logoSize.width}
          height={logoSize.height}
        />

        <nav
          className={classNames(styles.nav, {
            [styles.active]: open,
          })}
        >
          <ul className={styles.list}>
            <li className={styles.li}>
              <AnchorLink to={`/#${WHAT_IS_TELMIE}`} className={styles.link}>
                What is Telmie?
              </AnchorLink>
            </li>

            <li className={styles.li}>
              <a href={EXTERNAL_LINKS.faq} className={styles.link}>
                FAQ
              </a>
            </li>
          </ul>
        </nav>

        <div className={styles.polygon} />

        <div className={styles.buttonContainer}>
          <Button link href={EXTERNAL_LINKS.logIn} className={styles.loginButton}>
            Log in
          </Button>
          <Button link ghost href={EXTERNAL_LINKS.signUp} className={styles.signUpButton}>
            Sign up
          </Button>

          {isNarrow && <MenuButton show={open} onClick={onToggleMenu} />}
        </div>
      </header>
    </>
  );
};

Header.defaultProps = {
  open: false,
  onToggleMenu: () => {},
};

Header.propTypes = {
  open: PropTypes.bool,
  onToggleMenu: PropTypes.func,
};

export default Header;
