import React, { Fragment } from "react";
import PropTypes from "prop-types";

import Button from "../../../Button/Button";
import Input from "../../../Input/Input";
import HeroImage from "../Image/HeroImage";
import { TabsItem } from "../../../Tabs/Tabs";

import { MAIN_TABS_IDS } from "../../../../constants/tabs";
import { EXTERNAL_LINKS } from "../../../../constants/links";
import { BUTTON_SIZE, BUTTON_KIND } from "../../../../constants/button";

import moneyImage from "../../../../assets/images/hero/make-money.png";
import englishImage from "../../../../assets/images/hero/learn-english.png";
import tutorImage from "../../../../assets/images/hero/get-tutor.png";
import proImage from "../../../../assets/images/hero/talk-to-pro.png";
import corporateImage from "../../../../assets/images/hero/corporate-clients.png";

import styles from "./HerotabItem.module.scss";

const HeroTabItem = ({ id, className }) => {
  const getTabContent = (id) => {
    switch (id) {
      case MAIN_TABS_IDS.money: {
        return (
          <Fragment key={MAIN_TABS_IDS.money}>
            <div className={styles.container}>
              <div className={styles.info}>
                <h1>Register as a Telmie Pro and make money from day one</h1>
                <p className={styles.subtitle}>
                  Find new clients or monetise personal communication with existing customers and followers.
                </p>

                <div className={styles.row}>
                  <Button
                    link
                    className={styles.button}
                    href={EXTERNAL_LINKS.signUp}
                    size={BUTTON_SIZE.md}
                    kind={BUTTON_KIND.secondary}
                  >
                    Sign up to work
                  </Button>
                  <Button
                    link
                    ghost
                    className={styles.button}
                    href={EXTERNAL_LINKS.signUp}
                    size={BUTTON_SIZE.md}
                    kind={BUTTON_KIND.secondary}
                  >
                    Learn more
                  </Button>
                </div>
              </div>

              <div className={styles.imageContainer}>
                <HeroImage alt="make money" src={moneyImage} width={573} height={623} className={styles.image} />
              </div>
            </div>

            <div className={styles.footer}>
              <div className={styles.footerContent}>
                <h5 className={styles.authorName}>Jane W.</h5>
                <h6 className={styles.authorPosition}>Math tutor</h6>
                <q className={styles.authorQuote}>
                  I registered on the platform as a Pro and within minutes received paid incoming calls from young
                  learners and their parents, who subsequently became my regular clients.
                </q>
              </div>
            </div>
          </Fragment>
        );
      }

      case MAIN_TABS_IDS.learn: {
        return (
          <Fragment key={MAIN_TABS_IDS.learn}>
            <div className={styles.container}>
              <div className={styles.info}>
                <h1>Learn English with the best teachers on demand</h1>
                <p className={styles.subtitle}>
                  Affordable, high-quality one-to-one English lessons with the best teachers from all over the world,
                  from beginner to expert level.
                </p>

                <div className={styles.row}>
                  <Button
                    link
                    className={styles.button}
                    href={EXTERNAL_LINKS.signUp}
                    size={BUTTON_SIZE.md}
                    kind={BUTTON_KIND.secondary}
                  >
                    Try now for free
                  </Button>
                  <Button
                    link
                    ghost
                    className={styles.button}
                    href={EXTERNAL_LINKS.signUp}
                    size={BUTTON_SIZE.md}
                    kind={BUTTON_KIND.secondary}
                  >
                    Schedule for later
                  </Button>
                </div>
              </div>

              <div className={styles.imageContainer}>
                <HeroImage alt="learn English" src={englishImage} width={573} height={623} className={styles.image} />
              </div>
            </div>

            <div className={styles.footer} />
          </Fragment>
        );
      }

      case MAIN_TABS_IDS.tutor: {
        return (
          <Fragment key={MAIN_TABS_IDS.tutor}>
            <div className={styles.container}>
              <div className={styles.info}>
                <h1>Instantly hire the perfect tutor for you or your kids</h1>
                <p className={styles.subtitle}>
                  Access qualified and experienced tutors in languages, maths, physics, economics or anything else. Find
                  your perfect tutor and exchange messages or jump on a call at any time.
                </p>

                <Button
                  link
                  className={styles.buttonLink}
                  href={EXTERNAL_LINKS.signUp}
                  size={BUTTON_SIZE.md}
                  kind={BUTTON_KIND.secondary}
                >
                  Find your perfect tutor
                </Button>
              </div>

              <div className={styles.imageContainer}>
                <HeroImage alt="get a tutor" src={tutorImage} width={573} height={623} className={styles.image} />
              </div>
            </div>

            <div className={styles.footer} />
          </Fragment>
        );
      }

      case MAIN_TABS_IDS.pro: {
        return (
          <Fragment key={MAIN_TABS_IDS.pro}>
            <div className={styles.container}>
              <div className={styles.info}>
                <h1>Talk to a Pro you can trust in a matter of minutes</h1>
                <p className={styles.subtitle}>
                  Use the Telmie dedicated concierge service to find an expert in any field who is either trusted by
                  your social network or tried and tested by Telmie&rsquo;s top users.
                </p>

                <div className={styles.row}>
                  <Input placeholder="i.e. Psychologist" className={styles.input} />

                  <Button
                    link
                    className={styles.button}
                    href={EXTERNAL_LINKS.signUp}
                    size={BUTTON_SIZE.md}
                    kind={BUTTON_KIND.secondary}
                  >
                    Request
                  </Button>
                </div>
              </div>

              <div className={styles.imageContainer}>
                <HeroImage alt="Talk to a Pro" src={proImage} width={573} height={623} className={styles.image} />
              </div>
            </div>

            <div className={styles.footer} />
          </Fragment>
        );
      }

      case MAIN_TABS_IDS.corporate: {
        return (
          <Fragment key={MAIN_TABS_IDS.corporate}>
            <div className={styles.container}>
              <div className={styles.info}>
                <h1>Help employees reach their goals and excel at what they do</h1>
                <p className={styles.subtitle}>
                  Enable your teams to realise their full potential and reach their most challenging goals. Equip
                  employees with expertise and support that’s close to hand, instant and flexible.
                </p>

                <div className={styles.row}>
                  <Button
                    link
                    className={styles.button}
                    href={EXTERNAL_LINKS.signUp}
                    size={BUTTON_SIZE.md}
                    kind={BUTTON_KIND.secondary}
                  >
                    Enquire
                  </Button>
                  <Button
                    link
                    ghost
                    className={styles.button}
                    href={EXTERNAL_LINKS.signUp}
                    size={BUTTON_SIZE.md}
                    kind={BUTTON_KIND.secondary}
                  >
                    Try strengths coaching now
                  </Button>
                </div>
                {/*
                 <div className={styles.linkContainer}>
                  <a className={styles.link} href={EXTERNAL_LINKS.signUp}>
                    Gilead Sciences & Telmie collaboration
                  </a>
                </div> 
                */}
              </div>

              <div className={styles.imageContainer}>
                <HeroImage
                  alt="Help employees reach their goals"
                  src={corporateImage}
                  width={573}
                  height={623}
                  className={styles.image}
                />
              </div>
            </div>

            <div className={styles.footer}>
              <div className={styles.footerContent}>
                <h5 className={styles.authorName}>Andrew M.</h5>
                <h6 className={styles.authorPosition}>Senior Director at Gilead Sciences</h6>
                <q className={styles.authorQuote}>
                  For me, it was very timely to have the strengths coaching sessions through Telmie when I had just
                  moved to a new role that was pushing me out of my comfort zone.
                </q>
              </div>
            </div>
          </Fragment>
        );
      }

      default:
        return null;
    }
  };

  const content = getTabContent(id);

  return content && <TabsItem className={className}>{content}</TabsItem>;
};

HeroTabItem.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default HeroTabItem;
