import React, { memo } from "react";

const HeroPolygonIcon = memo(() => (
  <svg width="395" height="430" viewBox="0 0 395 430" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M157.839 10.5887C182.381 -3.52958 212.619 -3.52958 237.161 10.5887L355.339 78.571C379.881 92.6893 395 118.781 395 147.018V282.982C395 311.219 379.881 337.311 355.339 351.429L237.161 419.411C212.619 433.53 182.381 433.53 157.839 419.411L39.6614 351.429C15.1189 337.311 0 311.219 0 282.982V147.018C0 118.781 15.1189 92.6894 39.6614 78.571L157.839 10.5887Z"
      fill="currentColor"
    />
  </svg>
));

export default HeroPolygonIcon;
