import React from "react";

import findImage from "../../../assets/images/howItWorks/find.png";
import connectImage from "../../../assets/images/howItWorks/connect.png";
import rewardImage from "../../../assets/images/howItWorks/reward.png";

import styles from "./HowItWorks.module.scss";

const HowItWorks = () => {
  return (
    <section className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.titleContainer}>
          <h3 className={styles.title}>How it works</h3>
          <p className={styles.subtitle}>
            Telmie offers a simple yet powerful solution for
            <br /> communication-driven services
          </p>
        </div>

        <div className={styles.itemContainer}>
          <div className={styles.item}>
            <img src={findImage} alt="Find and get found" width={118} height={118} className={styles.itemImage} />
            <h4 className={styles.itemTitle}>Find and get found</h4>
            <p className={styles.itemText}>
              With Telmie, no searching is necessary to find the perfect Pro or client. Telmie does searching for you,
              so you can focus on the service and enjoy the results.
            </p>
          </div>

          <div className={styles.item}>
            <img src={connectImage} alt="Connect and achieve" width={118} height={118} className={styles.itemImage} />
            <h4 className={styles.itemTitle}>Connect and achieve</h4>
            <p className={styles.itemText}>
              Imagine having the best talent in your phone book, just a message away. Telmie is that phone book,
              inspired by WhatsApp and Zoom. You can send a text, book a session, make a call and enjoy instantly.
            </p>
          </div>

          <div className={styles.item}>
            <img src={rewardImage} alt="Earn and reward" width={118} height={118} className={styles.itemImage} />
            <h4 className={styles.itemTitle}>Earn and reward</h4>
            <p className={styles.itemText}>
              Top up or withdraw earnings with ease or link a default payment method and enjoy services using the
              automatic pay-as-you-talk payments pioneered by Telmie.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
