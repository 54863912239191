import React, { memo } from "react";

const InstagramIcon = memo(() => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.631 0C2.077 0 0 2.083 0 4.643v6.715A4.641 4.641 0 004.631 16h6.738A4.641 4.641 0 0016 11.358V4.643C16 2.083 13.923 0 11.369 0H4.63zm0 1.375h6.738a3.242 3.242 0 013.26 3.268v6.715a3.241 3.241 0 01-3.26 3.267H4.63a3.24 3.24 0 01-3.259-3.267V4.643a3.241 3.241 0 013.26-3.268zm7.663 1.376a.96.96 0 00-.96.962.96.96 0 101.92 0 .96.96 0 00-.96-.962zM8 3.88C5.738 3.88 3.89 5.732 3.89 8c0 2.267 1.848 4.12 4.11 4.12 2.262 0 4.11-1.853 4.11-4.12 0-2.267-1.848-4.12-4.11-4.12zm0 1.375c1.52 0 2.739 1.22 2.739 2.744A2.731 2.731 0 018 10.744 2.73 2.73 0 015.263 8 2.73 2.73 0 018 5.256z"
      fill="currentColor"
    />
  </svg>
));

export default InstagramIcon;
