import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./Input.module.scss";

const Input = ({ className, ...rest }) => {
  return <input {...rest} className={classNames(styles.input, className)} />;
};

Input.defaultProps = {
  className: "",
};

Input.propTypes = {
  className: PropTypes.string,
};

export default Input;
