import React, { useState, useEffect } from "react";
import classNames from "classnames";

import Icon from "../../Icon/Icon";
import HeroTabItem from "./HeroTabItem/HeroTabItem";
import { Tabs, TabsNav, TabsNavItem } from "../../Tabs/Tabs";

import { MAIN_TABS, MAIN_TABS_IDS } from "../../../constants/tabs";

import styles from "./Hero.module.scss";

const ACTIVE_TAB_ID = "__ACTIVE_TAB_ID__";

const Hero = () => {
  const [activeTab, setActiveTab] = useState(null);
  const resultTabId = activeTab || MAIN_TABS_IDS.money;

  useEffect(() => {
    const activeTabId = localStorage.getItem(ACTIVE_TAB_ID) || MAIN_TABS_IDS.money;
    setActiveTab(activeTabId);
  }, []);

  const handleSetActiveTab = (id) => {
    setActiveTab(id);
    localStorage.setItem(ACTIVE_TAB_ID, id);
  };

  return (
    <section className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.polygon} />
        <Tabs className={styles.tabContainer}>
          <TabsNav showIndicator className={styles.navContainer}>
            {MAIN_TABS.map((tab) => {
              const active = tab.id === activeTab;
              return (
                <TabsNavItem
                  key={tab.id}
                  id={tab.id}
                  active={active}
                  className={styles.navItem}
                  onClick={handleSetActiveTab}
                >
                  <Icon name={tab.icon} width={30} height={30} className={styles.navIcon} />
                  {tab.label}
                </TabsNavItem>
              );
            })}
          </TabsNav>

          <HeroTabItem id={resultTabId} className={classNames(styles.tabItem, { [styles.active]: activeTab })} />
        </Tabs>
      </div>

      <div className={styles.overview} />
    </section>
  );
};

export default Hero;
