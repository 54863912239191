import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import Icon from "../Icon/Icon";

import { LOGO_ICON } from "../../constants/icons";
import { HOME } from "../../constants/routes";

const Logo = ({ color, logoClassName, containerClassName, width, height }) => {
  return (
    <Link to={HOME} className={containerClassName}>
      <Icon name={LOGO_ICON} width={width} height={height} color={color} className={logoClassName} />
    </Link>
  );
};

Logo.defaultProps = {
  color: "",
  logoClassName: "",
  containerClassName: "",
  width: 112,
  height: 32,
};

Logo.propTypes = {
  color: PropTypes.string,
  logoClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Logo;
