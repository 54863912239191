export const LOGO_ICON = "LOGO_ICON";
export const LANGUAGE_ICON = "LANGUAGE_ICON";

export const FACEBOOK_ICON = "FACEBOOK_ICON";
export const INSTAGRAM_ICON = "INSTAGRAM_ICON";
export const LINKEDIN_ICON = "LINKEDIN_ICON";
export const TWITTER_ICON = "TWITTER_ICON";
export const YOUTUBE_ICON = "YOUTUBE_ICON";

export const CORPORATE_ICON = "CORPORATE_ICON";
export const ENGLISH_ICON = "ENGLISH_ICON";
export const MONEY_ICON = "MONEY_ICON";
export const PRO_ICON = "PRO_ICON";
export const TUTOR_ICON = "TUTOR_ICON";

export const ARROW_RIGHT_ICON = "ARROW_RIGHT_ICON";
export const CHAT_ICON = "CHAT_ICON";
export const ENVELOPE_ICON = "ENVELOPE_ICON";
export const NEWSPAPER_ICON = "NEWSPAPER_ICON";

export const HERO_POLYGON_ICON = "HERO_POLYGON_ICON";
