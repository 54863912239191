import React from "react";
import PropTypes from "prop-types";

import TabsItem from "./Item/Item";
import TabsNav from "./Nav/Nav";
import TabsNavItem from "./NavItem/NavItem";

const Tabs = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

Tabs.defaultProps = {
  className: "",
};

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export { TabsItem, TabsNav, TabsNavItem, Tabs };
