import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./Button.module.scss";

import { BUTTON_KIND, BUTTON_SIZE } from "../../constants/button";

const Button = ({ children, link, kind, ghost, size, ...rest }) => {
  const isClearKind = kind === BUTTON_KIND.clear;
  const isTextKind = kind === BUTTON_KIND.text;

  const className = classNames(styles.button, rest.className, {
    [styles.primary]: kind === BUTTON_KIND.primary,
    [styles.secondary]: kind === BUTTON_KIND.secondary,
    [styles.clear]: isClearKind,
    [styles.text]: isTextKind,
    [styles.ghost]: ghost,
    [styles.sm]: size === BUTTON_SIZE.sm && !isClearKind,
    [styles.md]: size === BUTTON_SIZE.md && !isClearKind,
    [styles.lg]: size === BUTTON_SIZE.lg && !isClearKind,
  });

  if (link) {
    return (
      <a href={rest.href} {...rest} className={className}>
        {children}
      </a>
    );
  }

  return (
    <button type="button" {...rest} className={className}>
      {children}
    </button>
  );
};

Button.defaultProps = {
  link: false,
  ghost: false,
  size: BUTTON_SIZE.sm,
  kind: BUTTON_KIND.primary,
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf([BUTTON_SIZE.sm, BUTTON_SIZE.md, BUTTON_SIZE.lg]),
  kind: PropTypes.oneOf([BUTTON_KIND.primary, BUTTON_KIND.secondary, BUTTON_KIND.clear, BUTTON_KIND.text]),
  link: PropTypes.bool,
  ghost: PropTypes.bool,
};

export default Button;
