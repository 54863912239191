import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { TabsItem } from "../../../Tabs/Tabs";

import { WHAT_IS_TABS_ITEMS } from "../../../../constants/tabs";

import styles from "./WhatIsTabItem.module.scss";

const WhatIsTabItem = ({ id }) => {
  return (
    <TabsItem className={styles.container}>
      {WHAT_IS_TABS_ITEMS.map((item) => {
        const active = item.id === id;

        return (
          <React.Fragment key={item.id}>
            <div
              className={classNames(styles.message, {
                [styles.active]: active,
              })}
            >
              {item.message}
            </div>
            <img
              src={item.image}
              alt={item.alt}
              className={classNames(styles.image, {
                [styles.active]: active,
              })}
              width={605}
              height={734}
            />
          </React.Fragment>
        );
      })}
    </TabsItem>
  );
};

WhatIsTabItem.propTypes = {
  id: PropTypes.string.isRequired,
};

export default WhatIsTabItem;
