import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Icon from "../../../Icon/Icon";

import { HERO_POLYGON_ICON } from "../../../../constants/icons";

import styles from "./HeroImage.module.scss";

class HeroImage extends Component {
  timeout;

  constructor(props) {
    super(props);

    this.state = {
      showImage: false,
      loaded: false,
    };
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({ showImage: true });
    });
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleOnLoad = () => {
    this.setState({ loaded: true });
  };

  render() {
    const { src, alt, ...rest } = this.props;
    const { loaded, showImage } = this.state;

    return (
      <div className={styles.container}>
        <Icon
          name={HERO_POLYGON_ICON}
          defaultIconStyle={false}
          className={classNames(styles.placeholder, rest.className, {
            [styles.loaded]: loaded,
          })}
        />
        {showImage && (
          <img
            src={src}
            alt={alt}
            {...rest}
            onLoad={this.handleOnLoad}
            className={classNames(styles.image, rest.className, {
              [styles.loaded]: loaded,
            })}
          />
        )}
      </div>
    );
  }
}

HeroImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default HeroImage;
