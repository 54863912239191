import React, { memo } from "react";

const ProIcon = memo(() => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="10" width="32" height="13" rx="6.5" fill="currentColor" />
    <path
      d="M7 20.12v-7h2.59c.56 0 1.023.093 1.39.28.367.187.64.443.82.77.18.327.27.693.27 1.1 0 .387-.087.743-.26 1.07-.173.32-.443.58-.81.78-.367.193-.837.29-1.41.29H8.28v2.71H7zm1.28-3.75h1.23c.447 0 .767-.097.96-.29.2-.2.3-.47.3-.81 0-.347-.1-.617-.3-.81-.193-.2-.513-.3-.96-.3H8.28v2.21zM13.074 20.12v-7h2.57c.56 0 1.02.097 1.38.29.367.187.64.443.82.77.18.32.27.677.27 1.07 0 .427-.113.817-.34 1.17-.22.353-.566.61-1.04.77l1.45 2.93h-1.47l-1.3-2.76h-1.06v2.76h-1.28zm1.28-3.7h1.21c.427 0 .74-.103.94-.31.2-.207.3-.48.3-.82 0-.333-.1-.6-.3-.8-.193-.2-.51-.3-.95-.3h-1.2v2.23zM22.585 20.24c-.7 0-1.314-.153-1.84-.46-.52-.307-.93-.73-1.23-1.27-.294-.547-.44-1.177-.44-1.89 0-.713.146-1.34.44-1.88.3-.547.71-.973 1.23-1.28.526-.307 1.14-.46 1.84-.46.693 0 1.303.153 1.83.46.526.307.936.733 1.23 1.28.293.54.44 1.167.44 1.88 0 .713-.147 1.343-.44 1.89a3.22 3.22 0 01-1.23 1.27c-.527.307-1.137.46-1.83.46zm0-1.15c.666 0 1.196-.22 1.59-.66.4-.44.6-1.043.6-1.81 0-.767-.2-1.37-.6-1.81-.394-.44-.924-.66-1.59-.66-.667 0-1.2.22-1.6.66-.4.44-.6 1.043-.6 1.81 0 .767.2 1.37.6 1.81.4.44.933.66 1.6.66z"
      fill="#fff"
    />
    <path
      d="M7 20.12v-7h2.59c.56 0 1.023.093 1.39.28.367.187.64.443.82.77.18.327.27.693.27 1.1 0 .387-.087.743-.26 1.07-.173.32-.443.58-.81.78-.367.193-.837.29-1.41.29H8.28v2.71H7zm1.28-3.75h1.23c.447 0 .767-.097.96-.29.2-.2.3-.47.3-.81 0-.347-.1-.617-.3-.81-.193-.2-.513-.3-.96-.3H8.28v2.21zM13.074 20.12v-7h2.57c.56 0 1.02.097 1.38.29.367.187.64.443.82.77.18.32.27.677.27 1.07 0 .427-.113.817-.34 1.17-.22.353-.566.61-1.04.77l1.45 2.93h-1.47l-1.3-2.76h-1.06v2.76h-1.28zm1.28-3.7h1.21c.427 0 .74-.103.94-.31.2-.207.3-.48.3-.82 0-.333-.1-.6-.3-.8-.193-.2-.51-.3-.95-.3h-1.2v2.23zM22.585 20.24c-.7 0-1.314-.153-1.84-.46-.52-.307-.93-.73-1.23-1.27-.294-.547-.44-1.177-.44-1.89 0-.713.146-1.34.44-1.88.3-.547.71-.973 1.23-1.28.526-.307 1.14-.46 1.84-.46.693 0 1.303.153 1.83.46.526.307.936.733 1.23 1.28.293.54.44 1.167.44 1.88 0 .713-.147 1.343-.44 1.89a3.22 3.22 0 01-1.23 1.27c-.527.307-1.137.46-1.83.46zm0-1.15c.666 0 1.196-.22 1.59-.66.4-.44.6-1.043.6-1.81 0-.767-.2-1.37-.6-1.81-.394-.44-.924-.66-1.59-.66-.667 0-1.2.22-1.6.66-.4.44-.6 1.043-.6 1.81 0 .767.2 1.37.6 1.81.4.44.933.66 1.6.66z"
      stroke="#fff"
      strokeWidth="0.4"
    />
  </svg>
));

export default ProIcon;
