import React, { memo } from "react";

const LinkedinIcon = memo(() => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.183 0C.53 0 0 .512 0 1.145v13.71C0 15.488.53 16 1.183 16h13.634C15.47 16 16 15.488 16 14.855V1.145C16 .512 15.47 0 14.817 0H1.183zm2.474 2.686c.827 0 1.333.538 1.35 1.247 0 .694-.525 1.247-1.368 1.247h-.013c-.811 0-1.336-.553-1.336-1.247 0-.709.54-1.247 1.367-1.247zM10.783 6c1.59 0 2.779 1.032 2.779 3.252v4.142h-2.416V9.528c0-.972-.349-1.635-1.223-1.635-.668 0-1.067.45-1.242.882-.064.154-.076.368-.076.584v4.035h-2.42s.032-6.547 0-7.225h2.42v1.02C8.926 6.697 9.502 6 10.783 6zm-8.35.17H4.85v7.224H2.434V6.17z"
      fill="currentColor"
    />
  </svg>
));

export default LinkedinIcon;
