import React, { memo } from "react";

const TutorIcon = memo(() => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.54 17.058c-.175.035-.35.07-.525.07-.176 0-.386-.035-.526-.07l-6.44-2.056-.21 3.58c-.035.531.28 1.028.91 1.453.63.46 1.54.815 2.625 1.064 1.12.248 2.345.39 3.64.39 1.296 0 2.52-.142 3.64-.39 1.121-.249 1.996-.603 2.626-1.064.63-.46.945-.922.91-1.453l-.21-3.58-6.44 2.056zM6.457 20.108L7.087 25h-2.87l.63-4.892c-.42-.283-.665-.709-.665-1.205 0-.567.245-.992.735-1.276.07-1.347.35-2.445.805-3.296l1.4.46c-.14.213-.28.497-.42.816a7.132 7.132 0 00-.385 2.02c.49.284.7.674.7 1.24.105.532-.105.922-.56 1.241z"
      fill="currentColor"
    />
    <path
      d="M27.707 11.705l-12.112 3.722a.296.296 0 01-.245 0L4.29 11.741a.432.432 0 010-.816L15.91 7.026a.382.382 0 01.28 0l11.552 3.9c.35.106.35.673-.035.78z"
      fill="currentColor"
    />
    <path
      d="M27.707 11.705l-12.112 3.722a.296.296 0 01-.245 0L4.29 11.741a.432.432 0 010-.816L15.91 7.026a.382.382 0 01.28 0l11.552 3.9c.35.106.35.673-.035.78z"
      fill="currentColor"
    />
  </svg>
));

export default TutorIcon;
