const DEFAULT_APP_HOST = "https://telmie.com";
const appHost = process.env.GATSBY_APP_HOST || DEFAULT_APP_HOST;

export const EXTERNAL_LINKS = {
  home: `${appHost}`,
  logIn: `${appHost}/log-in`,
  signUp: `${appHost}/sign-up`,
  blog: `${appHost}/#blog`,
  faq: `${appHost}/help`,
  contactUs: `${appHost}/contact-us`,
  privacy: `${appHost}/privacy`,
  terms: `${appHost}/terms`,
  becomePro: `${appHost}/#become-pro`,
  facebook: "https://www.facebook.com/telmie.pro",
  linkedin: "https://www.linkedin.com/company/telmie",
  twitter: "https://twitter.com/TelmieUK",
  instagram: "https://www.instagram.com/telmieuk",
  youtube: "https://www.youtube.com/channel/UCwphxwpZoBIT6Eg7_FFVzQQ",
  appStore: "https://apps.apple.com/app/telmie/id1345950689",
  googlePlay: "https://play.google.com/store/apps/details?id=com.starcloud.telmie",
};
