import React, { memo } from "react";

const FacebookIcon = memo(() => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.883 0A.882.882 0 000 .883v14.234c0 .488.395.883.883.883h7.663V9.805H6.461V7.39h2.085V5.609c0-2.067 1.263-3.192 3.106-3.192.883 0 1.642.066 1.863.095v2.16h-1.279c-1.002 0-1.197.476-1.197 1.175V7.39h2.392l-.312 2.415h-2.08V16h4.078a.883.883 0 00.883-.883V.883A.882.882 0 0015.117 0H.883z"
      fill="currentColor"
    />
  </svg>
));

export default FacebookIcon;
