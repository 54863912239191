import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { icons } from "../../assets/icons/icons";

import styles from "./Icon.module.scss";

const Icon = memo(({ name, color, width = 16, height = 16, className, defaultIconStyle = true }) => {
  const IconComponent = icons[name];
  const iconStyle = defaultIconStyle
    ? {
        width: `${width}px`,
        height: `${height}px`,
        color,
      }
    : {};

  return IconComponent ? (
    <span style={iconStyle} className={classNames(styles.icon, className)}>
      <IconComponent />
    </span>
  ) : null;
});

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  defaultIconStyle: PropTypes.bool,
  className: PropTypes.string,
};

export default Icon;
