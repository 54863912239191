import * as ICONS from "./icons";
import videoCallImage from "../assets/images/whatIs/video-calls.png";
import chatImage from "../assets/images/whatIs/chat.png";
import calendarImage from "../assets/images/whatIs/calendar.png";
import packageImage from "../assets/images/whatIs/packages.png";
import networkImage from "../assets/images/whatIs/professional-network.png";

export const MAIN_TABS_IDS = {
  money: "money",
  learn: "learn",
  tutor: "tutor",
  pro: "pro",
  corporate: "corporate",
};

export const MAIN_TABS = [
  {
    id: MAIN_TABS_IDS.money,
    label: "Make money",
    icon: ICONS.MONEY_ICON,
  },
  {
    id: MAIN_TABS_IDS.pro,
    label: "Talk to a Pro",
    icon: ICONS.PRO_ICON,
  },
  {
    id: MAIN_TABS_IDS.learn,
    label: "Learn English",
    icon: ICONS.ENGLISH_ICON,
  },
  {
    id: MAIN_TABS_IDS.tutor,
    label: "Get a tutor",
    icon: ICONS.TUTOR_ICON,
  },
  {
    id: MAIN_TABS_IDS.corporate,
    label: "Corporate clients",
    icon: ICONS.CORPORATE_ICON,
  },
];

export const WHAT_IS_TABS_IDS = {
  billing: "billing",
  chat: "chat",
  calendar: "calendar",
  packages: "packages",
  network: "network",
};

export const WHAT_IS_TABS = [
  {
    id: WHAT_IS_TABS_IDS.billing,
    label: "Video calls with flexible billing",
  },
  {
    id: WHAT_IS_TABS_IDS.chat,
    label: "Powerful chat",
  },
  {
    id: WHAT_IS_TABS_IDS.calendar,
    label: "Calendar, bookings and reminders",
  },
  {
    id: WHAT_IS_TABS_IDS.packages,
    label: "Packages and subscriptions",
  },
  {
    id: WHAT_IS_TABS_IDS.network,
    label: "Professional network",
  },
];

export const WHAT_IS_TABS_ITEMS = [
  {
    id: WHAT_IS_TABS_IDS.billing,
    message:
      "Call and accept calls on-demand without booking. Flexible billing removes boundaries and encourages productive communication.",
    image: videoCallImage,
    alt: "Video calls",
  },
  {
    id: WHAT_IS_TABS_IDS.chat,
    message:
      "Use chat like it has never been used before - to provide and receive service, make payments and get paid.",
    image: chatImage,
    alt: "Powerful chat",
  },
  {
    id: WHAT_IS_TABS_IDS.calendar,
    message:
      "Telmie offers a simple yet powerful booking system. Plan your time upfront and get a refund for cancelling in advance.",
    image: calendarImage,
    alt: "Calendar, bookings and reminders",
  },
  {
    id: WHAT_IS_TABS_IDS.packages,
    message:
      "Prepay sessions in bulk to get a better price. Or, if you are a Pro, reward regular clients with discounts.",
    image: packageImage,
    alt: "Packages and subscriptions",
  },
  {
    id: WHAT_IS_TABS_IDS.network,
    message:
      "Find the Pros trusted by your friends, family and colleagues. Promote your services through your clients’ social circles.",
    image: networkImage,
    alt: "Professional network",
  },
];
