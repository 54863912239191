import React, { memo } from "react";

const LogoIcon = memo(() => (
  <svg width="113" height="32" viewBox="0 0 113 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="a" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="32">
      <path
        d="M16.093.39l11.622 6.546c.922.52 1.49 1.479 1.49 2.518v13.092a2.895 2.895 0 01-1.49 2.518L16.093 31.61c-.922.52-2.059.52-2.98 0L1.49 25.064A2.895 2.895 0 010 22.546V9.454c0-1.039.568-1.999 1.49-2.518L13.113.39a3.045 3.045 0 012.98 0z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#a)">
      <path fillRule="evenodd" clipRule="evenodd" d="M-2.98 34.907h35.165V-2.907H-2.98v37.814z" fill="#E4002C" />
    </g>
    <mask id="b" maskUnits="userSpaceOnUse" x="5" y="13" width="6" height="15">
      <path d="M10.013 27.228L5.007 24.37V13.39l5.006 2.878v10.96z" fill="#fff" />
    </mask>
    <g mask="url(#b)">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.878 30.111h11.265V10.506H1.878v19.605z" fill="#FFADB1" />
    </g>
    <mask id="c" maskUnits="userSpaceOnUse" x="15" y="13" width="6" height="9">
      <path d="M20.026 21.51l-5.006-2.84v-5.28l5.006 2.888v5.232z" fill="#fff" />
    </mask>
    <g mask="url(#c)">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.89 24.417h11.265V10.482H11.891v13.935z" fill="#FFADB1" />
    </g>
    <mask id="d" maskUnits="userSpaceOnUse" x="10" y="15" width="6" height="9">
      <path d="M15.02 23.972l-5.006-2.893v-5.248l5.006 2.939v5.202z" fill="#fff" />
    </mask>
    <g mask="url(#d)">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.885 26.88H18.15V12.923H6.885v13.955z" fill="#FFADB1" />
    </g>
    <mask id="e" maskUnits="userSpaceOnUse" x="20" y="5" width="6" height="15">
      <path d="M25.033 19.087l-5.007-2.782V5.25l5.007 2.782v11.056z" fill="#fff" />
    </mask>
    <g mask="url(#e)">
      <path fillRule="evenodd" clipRule="evenodd" d="M16.898 21.97h11.264V2.367H16.898V21.97z" fill="#FFADB1" />
    </g>
    <mask id="f" maskUnits="userSpaceOnUse" x="10" y="15" width="6" height="6">
      <path d="M10.014 20.714l5.006-2.144-5.006-2.739v4.883z" fill="#fff" />
    </mask>
    <g mask="url(#f)">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.885 23.428H18.15v-10.31H6.885v10.31z" fill="#fff" />
    </g>
    <mask id="g" maskUnits="userSpaceOnUse" x="15" y="10" width="10" height="7">
      <path d="M19.597 16.64l-4.578-2.832 4.567-2.861 4.612 2.862-4.601 2.831z" fill="#fff" />
    </mask>
    <g mask="url(#g)">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.151 19.49h14.916V8.097H12.15v11.391z" fill="#fff" />
    </g>
    <path
      d="M97.239 25.193h-2.385V11.402h2.385v13.79zm-5.96 0h-2.324v-8.248c0-1.838-1.007-3.434-3.05-3.434-2.156 0-3.667 1.298-3.667 3.407v8.275h-2.295v-8.437c0-2.001-1.12-3.245-3.051-3.245-2.072 0-3.583 1.298-3.583 3.326v8.356h-2.295V11.78c.644.244 1.23.677 1.707 1.163 1.175-1.082 2.66-1.541 4.283-1.541 1.735 0 3.443.54 4.282 1.974.98-1.46 2.883-1.974 4.618-1.974 3.583 0 5.374 2.136 5.374 5.489v8.302zM40.02 20.028V5.656h2.268v5.994h4.15c.056.348.056.67.056 1.018 0 .347 0 .696-.057 1.07H42.29v6.317c0 2.81 1.798 3.05 4.231 3.05.057.348.057.643.057.99 0 .322 0 .697-.056 1.045-.332.026-.72.053-1.078.053-3.568 0-5.422-1.686-5.422-5.165zm63.039-3.49v1.015c.831.38 1.743.457 2.655.457 1.66 0 3.901-.457 3.901-2.336 0-1.575-1.273-2.26-2.849-2.26-2.268 0-3.707 1.04-3.707 3.123zm-.024 3.04c-.028.208 0 .626 0 .626 0 2.168 1.49 2.978 3.738 2.978 1.067 0 2.079-.314 2.895-.94.561.391 1.095.887 1.489 1.436-1.124 1.019-2.754 1.515-4.3 1.515-3.906 0-6.182-1.724-6.182-5.407v-3.395c0-3.135 2.697-4.99 5.929-4.99 3.035 0 5.395 1.463 5.395 4.493 0 3.239-3.203 4.18-6.154 4.18a7.848 7.848 0 01-2.81-.497zM96.019 9.102c-.8 0-1.463-.632-1.463-1.423 0-.79.663-1.45 1.463-1.45.827 0 1.518.66 1.518 1.45 0 .79-.691 1.423-1.518 1.423zM68.586 25.14c-.359.053-.744.053-1.076.053-3.312 0-5.437-1.846-5.437-5.112V5.656h2.314v14.399c-.05 2.783 1.798 3.05 4.199 3.05.055.322.042.643.042.964 0 .348.013.696-.042 1.071zM54.455 13.413c-2.268 0-3.706 1.042-3.706 3.124v1.016c.83.38 1.743.457 2.655.457 1.66 0 3.901-.457 3.901-2.336 0-1.575-1.273-2.26-2.85-2.26zm-.92 6.66a7.846 7.846 0 01-2.81-.496c-.028.21 0 .627 0 .627 0 2.168 1.49 2.978 3.738 2.978 1.068 0 2.08-.314 2.895-.94.561.391 1.096.887 1.49 1.436-1.125 1.019-2.755 1.515-4.301 1.515-3.906 0-6.182-1.724-6.182-5.407v-3.395c0-3.135 2.698-4.99 5.929-4.99 3.035 0 5.395 1.463 5.395 4.493 0 3.239-3.203 4.18-6.153 4.18z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.4"
    />
  </svg>
));

export default LogoIcon;
