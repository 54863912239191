import React, { memo } from "react";

const MoneyIcon = memo(() => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 6a10 10 0 100 20 10 10 0 000-20zm3.36 14.728c-.623.601-1.518.945-2.688 1.032v1.44h-1.654v-1.46c-1.046-.113-1.838-.42-2.375-.919-.538-.499-.848-1.205-.93-2.119h2.458a1.238 1.238 0 00.57.898c.31.192.795.288 1.456.288.404.019.803-.096 1.135-.327a1.037 1.037 0 00.434-.87 1.128 1.128 0 00-.326-.814 3.36 3.36 0 00-1.224-.677 41.12 41.12 0 01-1.55-.607 10.392 10.392 0 01-1.529-.794 3.101 3.101 0 01-.998-.999c-.23-.439-.34-.93-.32-1.425a2.788 2.788 0 01.848-2.095c.564-.546 1.35-.886 2.354-1.02V8.782h1.65v1.44c1.13.083 1.963.379 2.5.887a2.834 2.834 0 01.846 2.057h-2.416a.96.96 0 00-.435-.92 2.142 2.142 0 00-1.238-.325 2.318 2.318 0 00-1.291.288.945.945 0 00-.423.832c-.01.265.075.525.24.732.258.26.568.462.91.593.311.148.632.275.96.382a17.544 17.544 0 011.9.742c.305.139.595.31.865.51.39.266.71.625.928 1.044.187.41.279.857.27 1.308a3.154 3.154 0 01-.927 2.376z"
      fill="currentColor"
    />
  </svg>
));

export default MoneyIcon;
