import React, { Children, cloneElement, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./Nav.module.scss";

const TabsNav = ({ children, showIndicator, className }) => {
  const navItemRef = useRef(null);
  const navRef = useRef(null);
  const [bounds, setBounds] = useState({});

  useEffect(() => {
    let width = 0;
    let left = 0;

    if (navItemRef.current && showIndicator) {
      const bounds = navItemRef.current.getBoundingClientRect();
      width = bounds.width;
      left = bounds.left - navRef.current.getBoundingClientRect().left;

      setBounds({ width, left });
    }
  }, [children, showIndicator, setBounds]);

  const getContent = () => {
    if (showIndicator) {
      return (
        <>
          {Children.map(children, (child) =>
            cloneElement(child, {
              ref: (element) => {
                if (child.props.active) {
                  navItemRef.current = element;
                }

                const { ref } = child;
                if (typeof ref === "function") {
                  ref(element);
                }
              },
            })
          )}

          <div
            className={styles.indicator}
            style={{
              width: bounds.width || 0,
              transform: `translateX(${bounds.left || 0}px)`,
            }}
          />
        </>
      );
    }

    return children;
  };

  const content = getContent();

  return (
    <div ref={navRef} className={classNames(styles.container, className)}>
      {content}
    </div>
  );
};

TabsNav.defaultProps = {
  className: "",
  showIndicator: false,
};

TabsNav.propTypes = {
  children: PropTypes.node.isRequired,
  showIndicator: PropTypes.bool,
  className: PropTypes.string,
};

export default TabsNav;
