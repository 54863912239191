import * as ICONS from "../../constants/icons";

import LogoIcon from "./common/logo";
import LanguageIcon from "./common/language";
import FacebookIcon from "./social/facebook";
import InstagramIcon from "./social/instagram";
import LinkedinIcon from "./social/linkedin";
import TwitterIcon from "./social/twitter";
import YoutubeIcon from "./social/youtube";
import CorporateIcon from "./tabs/corporate";
import EnglishIcon from "./tabs/english";
import MoneyIcon from "./tabs/money";
import ProIcon from "./tabs/pro";
import TutorIcon from "./tabs/tutor";
import ArrowRightIcon from "./common/arrow-right";
import ChatIcon from "./common/chat";
import EnvelopeIcon from "./common/envelope";
import NewspaperIcon from "./common/newspaper";
import HeroPolygonIcon from "./common/hero-polygon";

export const icons = {
  [ICONS.LOGO_ICON]: LogoIcon,
  [ICONS.LANGUAGE_ICON]: LanguageIcon,

  [ICONS.FACEBOOK_ICON]: FacebookIcon,
  [ICONS.INSTAGRAM_ICON]: InstagramIcon,
  [ICONS.LINKEDIN_ICON]: LinkedinIcon,
  [ICONS.TWITTER_ICON]: TwitterIcon,
  [ICONS.YOUTUBE_ICON]: YoutubeIcon,

  [ICONS.CORPORATE_ICON]: CorporateIcon,
  [ICONS.ENGLISH_ICON]: EnglishIcon,
  [ICONS.MONEY_ICON]: MoneyIcon,
  [ICONS.PRO_ICON]: ProIcon,
  [ICONS.TUTOR_ICON]: TutorIcon,

  [ICONS.ARROW_RIGHT_ICON]: ArrowRightIcon,
  [ICONS.CHAT_ICON]: ChatIcon,
  [ICONS.ENVELOPE_ICON]: EnvelopeIcon,
  [ICONS.NEWSPAPER_ICON]: NewspaperIcon,

  [ICONS.HERO_POLYGON_ICON]: HeroPolygonIcon,
};
