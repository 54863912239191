import React, { memo } from "react";

const LanguageIcon = memo(() => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.619 12.8c.113 2.433.74 4.443 1.581 5.236V12.8H9.619zm0-1.6H11.2V5.964c-.841.793-1.468 2.803-1.581 5.236zm4.762 0c-.113-2.433-.74-4.443-1.581-5.236V11.2h1.581zm0 1.6H12.8v5.236c.841-.793 1.468-2.803 1.581-5.236zm-8.731 0a6.406 6.406 0 003.58 4.971c-.668-1.282-1.114-3.024-1.21-4.971H5.65zm0-1.6h2.37c.096-1.947.542-3.689 1.21-4.971A6.406 6.406 0 005.65 11.2zm12.7 0a6.406 6.406 0 00-3.58-4.971c.668 1.282 1.114 3.024 1.21 4.971h2.37zm0 1.6h-2.37c-.096 1.947-.542 3.689-1.21 4.971a6.406 6.406 0 003.58-4.971zM12 20a8 8 0 110-16 8 8 0 010 16z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.143 5.872A7.983 7.983 0 0120 12a7.977 7.977 0 01-2.507 5.816c-1.075-2.421-9.91-2.421-10.986 0A7.977 7.977 0 014 12c0-2.46 1.11-4.66 2.857-6.128 1.811 1.77 8.475 1.77 10.286 0zM7.087 7.897A6.374 6.374 0 005.6 12c0 1.289.382 2.514 1.072 3.548 2.75-1.53 7.906-1.53 10.656 0A6.371 6.371 0 0018.4 12c0-1.53-.538-2.969-1.487-4.103-2.649 1.204-7.177 1.204-9.826 0z"
      fill="currentColor"
    />
  </svg>
));

export default LanguageIcon;
