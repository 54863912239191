import React from "react";
import PropTypes from "prop-types";

const TabsItem = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

TabsItem.defaultProps = {
  className: "",
};

TabsItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default TabsItem;
