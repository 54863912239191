import React, { useState } from "react";
import classNames from "classnames";
import { useMedia } from "use-media";

import WhatIsTabItem from "./WhatIsTabItem/WhatIsTabItem";
import { Tabs, TabsNav, TabsNavItem } from "../../Tabs/Tabs";

import { MOBILE_SCREEN } from "../../../constants/breakpoints";
import { WHAT_IS_TABS, WHAT_IS_TABS_IDS } from "../../../constants/tabs";
import { WHAT_IS_TELMIE } from "../../../constants/routes";

import styles from "./Whatis.module.scss";

const WhatIs = () => {
  const isNarrow = useMedia({ maxWidth: MOBILE_SCREEN });
  const [activeTab, setActiveTab] = useState(WHAT_IS_TABS_IDS.billing);

  return (
    <section className={styles.container} id={WHAT_IS_TELMIE}>
      <div className={styles.innerContainer}>
        <div className={styles.info}>
          <h6 className={styles.subtitle}>What is Telmie?</h6>
          <h2>The platform for communication-based services</h2>
        </div>
        <Tabs className={styles.content}>
          <TabsNav className={styles.navigation}>
            {WHAT_IS_TABS.map((tab) => {
              const active = tab.id === activeTab;
              return (
                <TabsNavItem
                  key={tab.id}
                  id={tab.id}
                  className={classNames(styles.navigationItem, {
                    [styles.active]: active,
                  })}
                  onClick={(id) => setActiveTab(id)}
                >
                  {tab.label}
                </TabsNavItem>
              );
            })}

            {isNarrow && <div className={styles.fakeHorizontalMargin} />}
          </TabsNav>

          <WhatIsTabItem id={activeTab} />
        </Tabs>
      </div>
    </section>
  );
};

export default WhatIs;
