import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./NavItem.module.scss";

const TabsNavItem = forwardRef(({ id, active, children, className, onClick }, ref) => {
  const handleClick = () => {
    if (!active) {
      onClick(id);
    }
  };

  const classes = classNames(styles.button, className);

  return (
    <button ref={ref} type="button" className={classes} onClick={handleClick}>
      {children}
    </button>
  );
});

TabsNavItem.defaultProps = {
  className: "",
  active: false,
  onClick: () => {},
};

TabsNavItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default TabsNavItem;
