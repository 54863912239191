import React from "react";

import Icon from "../../Icon/Icon";
import Button from "../../Button/Button";

import { BUTTON_KIND, BUTTON_SIZE } from "../../../constants/button";
import { EXTERNAL_LINKS } from "../../../constants/links";
import { ARROW_RIGHT_ICON } from "../../../constants/icons";

import styles from "./JoinUs.module.scss";

const JoinUs = () => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <h2>
          Join 25,000+
          <br />
          registered users
        </h2>

        <div className={styles.statsContainer}>
          <div className={styles.statsItem}>
            <h4>5,000</h4>
            <h6 className={styles.statsLabel}>Active users</h6>
          </div>

          <div className={styles.statsItem}>
            <h4>800+</h4>
            <h6 className={styles.statsLabel}>experts</h6>
          </div>

          <div className={styles.statsItem}>
            <h4>92,880</h4>
            <h6 className={styles.statsLabel}>Minutes talked</h6>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <Button link href={EXTERNAL_LINKS.signUp} kind={BUTTON_KIND.text} size={BUTTON_SIZE.lg}>
            Sign up to work
            <Icon name={ARROW_RIGHT_ICON} width={30} height={30} className={styles.buttonIcon} />
          </Button>
          <Button link href={EXTERNAL_LINKS.signUp} kind={BUTTON_KIND.text} size={BUTTON_SIZE.lg}>
            Sign up to achieve
            <Icon name={ARROW_RIGHT_ICON} width={30} height={30} className={styles.buttonIcon} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default JoinUs;
