import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Button from "../Button/Button";

import { BUTTON_KIND } from "../../constants/button";

import styles from "./MenuButton.module.scss";

const MenuButton = ({ show, onClick }) => {
  return (
    <Button
      kind={BUTTON_KIND.clear}
      className={classNames(styles.container, { [styles.active]: show })}
      onClick={onClick}
    >
      <div className={styles.bar} />
      <div className={styles.bar} />
    </Button>
  );
};

MenuButton.defaultProps = {
  show: false,
  onClick: () => {},
};

MenuButton.propTypes = {
  onClick: PropTypes.func,
  show: PropTypes.bool,
};

export default MenuButton;
