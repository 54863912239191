import React, { memo } from "react";

const EnvelopeIcon = memo(() => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.615 9c-.211 0-.412.044-.597.118l9.373 7.976c.41.35.867.35 1.278 0l9.381-7.985A1.622 1.622 0 0025.462 9H6.614zm-1.598 1.38a1.67 1.67 0 00-.017.235v10.77C5 22.279 5.72 23 6.615 23h18.846c.895 0 1.616-.72 1.616-1.615v-10.77c0-.079-.006-.159-.017-.235l-9.339 7.942a2.623 2.623 0 01-3.382 0L5.017 10.38z"
      fill="currentColor"
    />
  </svg>
));

export default EnvelopeIcon;
