import React, { memo } from "react";

const EnglishIcon = memo(() => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.556 6H8.444C7.09 6 6 7.022 6 8.289v11.489c0 1.266 1.089 2.289 2.444 2.289h1.6v3.4c0 .2.134.4.334.489.089.044.155.044.244.044a.749.749 0 00.378-.111l5.044-3.822h7.512c1.355 0 2.444-1.023 2.444-2.29V8.29C26 7.022 24.911 6 23.556 6zm-8.2 11.222h-4.912v-6.489H15.2v1.156h-3.422v1.378h3.155V14.4h-3.155v1.667h3.6v1.155h-.022zm6.31 0h-1.355l-2.644-4.6v4.6H16.4v-6.489h1.422l2.578 4.534v-4.534h1.267v6.49z"
      fill="currentColor"
    />
  </svg>
));

export default EnglishIcon;
