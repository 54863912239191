import React, { useEffect, useState, useRef, useCallback } from "react";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

import Seo from "../components/Seo";
import Hero from "../components/sections/Hero/Hero";
import WhatIs from "../components/sections/WhatIs/WhatIs";
import HowItWorks from "../components/sections/HowItWorks/HowItWorks";
import JoinUs from "../components/sections/JoinUs/JoinUs";
import ContactUs from "../components/sections/ContactUs/ContactUs";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

const IndexPage = () => {
  const containerRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleToggleMenu = useCallback(() => {
    const nextValue = !open;

    if (nextValue) {
      disableBodyScroll(containerRef.current, { reserveScrollBarGap: true });
    } else {
      enableBodyScroll();
      clearAllBodyScrollLocks();
    }

    setOpen(!open);
  }, [open]);

  useEffect(() => {
    if (open) {
      window.addEventListener("scroll", handleToggleMenu);
    }

    return () => {
      window.removeEventListener("scroll", handleToggleMenu);
    };
  }, [open, handleToggleMenu]);

  return (
    <div ref={containerRef} className="container">
      <Seo />

      <Header open={open} onToggleMenu={handleToggleMenu} />
      <Hero />
      <WhatIs />
      <HowItWorks />
      <JoinUs />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default IndexPage;
