import React, { memo } from "react";

const TwitterIcon = memo(() => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2.595a6.385 6.385 0 01-1.885.536 3.395 3.395 0 001.443-1.882 6.426 6.426 0 01-2.085.825A3.225 3.225 0 0011.079 1C9.265 1 7.796 2.523 7.796 4.401c0 .267.028.527.084.775-2.728-.141-5.146-1.496-6.765-3.553a3.477 3.477 0 00-.445 1.71c0 1.18.58 2.22 1.46 2.83a3.178 3.178 0 01-1.486-.425v.043c0 1.648 1.13 3.023 2.632 3.335a3.175 3.175 0 01-1.482.059c.418 1.351 1.63 2.334 3.067 2.362A6.444 6.444 0 010 12.946a9.059 9.059 0 005.032 1.528c6.038 0 9.339-5.183 9.339-9.678 0-.148-.004-.294-.01-.44A6.825 6.825 0 0016 2.595"
      fill="currentColor"
    />
  </svg>
));

export default TwitterIcon;
