import React from "react";

import Icon from "../../Icon/Icon";

import * as ICONS from "../../../constants/icons";
import { EXTERNAL_LINKS } from "../../../constants/links";

import styles from "./ContactUs.module.scss";

const ContactUs = () => {
  return (
    <section className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.itemContainer}>
          <a href={EXTERNAL_LINKS.faq} className={styles.item}>
            <Icon name={ICONS.CHAT_ICON} className={styles.itemIcon} width={30} height={30} />
            <h4>FAQ</h4>
            <p className={styles.itemText}>Find answers to the most frequently asked questions about Telmie.</p>
            <span className={styles.itemLink}>Learn more</span>
          </a>

          <a href={EXTERNAL_LINKS.blog} className={styles.item}>
            <Icon name={ICONS.NEWSPAPER_ICON} className={styles.itemIcon} width={30} height={30} />
            <h4>Blog</h4>
            <p className={styles.itemText}>
              Check out our blog for interesting thoughts on remote work and freelancing culture.
            </p>
            <span className={styles.itemLink}>Learn more</span>
          </a>

          <a href={EXTERNAL_LINKS.contactUs} className={styles.item}>
            <Icon name={ICONS.ENVELOPE_ICON} className={styles.itemIcon} width={30} height={30} />
            <h4>Contact Us</h4>
            <p className={styles.itemText}>
              Email us at info@telmie.com, visit our social channels using the icons in the footer or click &rsquo;learn
              more&rsquo; to send us a message through our website.
            </p>
            <span className={styles.itemLink}>Learn more</span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
