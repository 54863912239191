import React, { memo } from "react";

const YoutubeIcon = memo(() => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 2c-3.138 0-6.276.255-6.966.764-1.379 1.02-1.379 9.278 0 10.298 1.38 1.019 12.553 1.019 13.932 0 1.379-1.02 1.379-9.278 0-10.298C14.276 2.254 11.138 2 8 2zM6.452 5.575l4.129 2.476-4.13 2.475v-4.95z"
      fill="currentColor"
    />
  </svg>
));

export default YoutubeIcon;
