import React, { memo } from "react";

const NewspaperIcon = memo(() => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.047 8v15.008c0 .76-.289 1.455-.756 1.992h15.896c1.251 0 2.125-.874 2.125-2.125V8H10.047zm10.094 2.656h4.25a.53.53 0 110 1.063h-4.25a.53.53 0 110-1.063zM5 11.187v11.82C5 24.112 5.889 25 6.992 25a1.988 1.988 0 001.992-1.992v-11.82H5zm7.969 0h4.25v3.72h-4.25v-3.72zm7.172 3.188h4.25a.53.53 0 110 1.063h-4.25a.53.53 0 110-1.063zm-7.172 3.453H24.39a.53.53 0 110 1.063H12.969a.53.53 0 110-1.063zm0 3.453H24.39a.53.53 0 110 1.063H12.969a.53.53 0 110-1.063z"
      fill="currentColor"
    />
  </svg>
));

export default NewspaperIcon;
