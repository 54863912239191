import React, { memo } from "react";

const CorporateIcon = memo(() => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.8 10.77h-4.2V9.23c0-.799-.618-1.23-1.2-1.23h-4.5c-1.1 0-1.5.736-1.5 1.23v1.54H8.2c-.663 0-1.2.584-1.2 1.264v4.581l9 1.846 9-1.846v-4.581c0-.68-.537-1.265-1.2-1.265zm-5.4 0h-4.8V9.286c.023-.016.104-.056.3-.056h4.483c.005 0 .011 0 .017.002v1.536zM17.5 20.384h-3v-.615l-6.9-1.23v5.23c0 .68.537 1.23 1.2 1.23h14.4c.662 0 1.2-.55 1.2-1.23v-5.23l-6.9 1.23v.616z"
      fill="currentColor"
    />
  </svg>
));

export default CorporateIcon;
